/* eslint-disable no-prototype-builtins */
class ImisEntity {
  constructor (source) {
    Object.defineProperty(this, '_data', { enumerable: false, writable: true })
    this._data = typeof source === 'string' ? JSON.parse(source) : source
    if (typeof this._data === 'object') {
      if (this._data.$type === 'Asi.Soa.Core.DataContracts.GenericEntityData, Asi.Contracts') {
        // Generic entity data. Pivot and flatten properties.
        this._data.Properties.$values.forEach((property) => {
          Object.defineProperty(this, property.Name, {
            get () {
              if (typeof property.Value === 'object') { return property.Value.$value } else { return property.Value }
            },
            set (value) {
              if (typeof property.Value === 'object') { property.Value.$value = value } else { property.Value = value }
            },
            enumerable: true,
            configurable: true
          })
        })
      } else {
        // Other entity types. Flatten collection $values to simple arrays.
        Object.getOwnPropertyNames(this._data).forEach((propertyName) => {
          if (this._data[propertyName] && typeof this._data[propertyName] === 'object') {
            if (this._data[propertyName].hasOwnProperty('$type') &&
                  this._data[propertyName].hasOwnProperty('$values') &&
                  Array.isArray(this._data[propertyName].$values) &&
                  this._data[propertyName].$type.toLowerCase().includes('collection')) {
              const arr = this._data[propertyName].$values.map((item) => {
                if (item && typeof item === 'object') { return new ImisEntity(item) } else { return item }
              })
              Object.defineProperty(this, propertyName, {
                get () {
                  return arr
                },
                set (value) {
                  this._data[propertyName].$values = value
                },
                enumerable: true,
                configurable: true
              })
            } else {
              const obj = new ImisEntity(this._data[propertyName])
              Object.defineProperty(this, propertyName, {
                get () {
                  return obj
                },
                set (value) {
                  this._data[propertyName] = value
                },
                enumerable: true,
                configurable: true
              })
            }
          } else {
            if (propertyName.toLowerCase() !== '$type') {
              Object.defineProperty(this, propertyName, {
                get () {
                  return this._data[propertyName]
                },
                set (value) {
                  this._data[propertyName] = value
                },
                enumerable: true,
                configurable: true
              })
            }
          }
        })
      }
    }
  }

  getObject () {
    return this._data
  }

  getJSON () {
    return JSON.stringify(this._data)
  }
}
/* eslint-enable no-prototype-builtins */

class ImisEntityCollection {
  constructor (source) {
    Object.defineProperty(this, '_data', { enumerable: false, writable: true })
    this._data = typeof source === 'string' ? JSON.parse(source) : source
    if (typeof this._data === 'object' &&
        this._data.$type &&
        this._data.$type.startsWith('Asi.Soa.Core.DataContracts.PagedResult')
    ) {
      this.Offset = this._data.Offset
      this.Limit = this._data.Limit
      this.Count = this._data.Count
      this.TotalCount = this._data.TotalCount
      this.HasNext = this._data.HasNext
      this.NextOffset = this._data.NextOffset
      this.Entities = []
      this._data.Items.$values.forEach((entity) => {
        this.Entities.push(new ImisEntity(entity))
      })
    }
  }
}

export default {
  ImisEntity, ImisEntityCollection
}
