<template>
	<div v-if="ready">
		<pre v-if="showConfig">{{config.Settings}}</pre>
	</div>
</template>

<script>
import mtapi from './mtapi'

export default {
	name: 'display'
	,props: ['ContentKey', 'ContentItemKey']
	,components: {}
	,data(){
		return {
			dev: (new URLSearchParams(window.location.search)).get('showConsole')??false //in URL: &showConsole=true
			,showConfig: (new URLSearchParams(window.location.search)).get('showConfig')??false //in URL: &showConfig=true
			,config: { Settings: {} }
			,ready: false
			,advertsByID: {} // populated by loadAdverts() and used by loadRandomSlots->loadAdvertToSlot()
		}
	}
	,async created(){
		let response 	=	await mtapi.axios.get(`/api/ContentItem?ContentKey=${this.ContentKey}&ContentItemKey=${this.ContentItemKey}`);
		this.config		=	(response?.data?.$type?.startsWith(`Asi.Soa.Core.DataContracts.PagedResult`))?response?.data?.Items?.$values[0]?.Data:response?.data;
		await this.start();
	}
	,methods:{
		async start(){
			this.loadCSS();
			this.loadAdverts();
			this.loadRandomSlots();
			this.ready		=	(this.showConfig||this.dev)?true:false;
		}
		,loadCSS(){
			console.log(this.config.Settings);
			if(this.config.Settings.customcss.length){
				let head			=	document.getElementsByTagName('head')[0];
				let styletag		=	document.createElement('style');
				styletag.innerText	=	this.config.Settings.customcss.replaceAll("\n",' ');
				head.appendChild(styletag);
			}
		}
		,loadAdverts(){
			this.config.Settings.adverts.forEach(advert=>{
				let target	=	document.getElementById(advert.id);
				this.advertsByID[advert.id]	=	advert;
				if(target){
					let contents	=	`<picture><source srcset="${advert.imageurl}" media="(min-width: ${this.config.Settings.advertsizes.mobilebreakpoint}px)" /><img src="${advert.mobileurl}" /></picture>`;
					let link		=	document.createElement('a');
					link.href		=	advert.url;
					link.target		=	"_blank";
					link.innerHTML	=	contents;
					target.appendChild(link);
				}
			});
		}
		,loadRandomSlots(){
			for(let slot in this.config.Settings.randomslots){
				let advertids	=	this.config.Settings.randomslots[slot];
				let randind		=	advertids[Math.floor(Math.random() * advertids.length)];
				this.loadAdvertToSlot(randind,slot);
			}
		}
		,loadAdvertToSlot(advertid,slotid){
			let target	=	document.getElementById(slotid);
			let advert	=	this.advertsByID[advertid];
			if(target){
				let contents	=	`<picture><source srcset="${advert.imageurl}" media="(min-width: ${this.config.Settings.advertsizes.mobilebreakpoint}px)" /><img src="${advert.mobileurl}" /></picture>`;
				let link		=	document.createElement('a');
				link.href		=	advert.url;
				link.target		=	"_blank";
				link.innerHTML	=	contents;
				target.appendChild(link);
			}
		}
	}
}
</script>

<style scoped lang="scss">
#app{ 
	color: inherit;
}
</style>
