import './public-path'
import Vue from 'vue'
import Display from '../Display.vue'
import mtapi from '../mtapi'

Vue.config.productionTip = false

mtapi.ready(() => {
  const iParts = Array.from(document.getElementsByTagName(process.env.VUE_APP_NAME)).map(x => x.id)
  iParts.forEach((id) => {
    const ContentKey = document.getElementById(id).attributes['x-content-key'].value
    const ContentItemKey = document.getElementById(id).attributes['x-content-item-key'].value
    new Vue({
      render: h => h(Display, {
        props: {
          ContentKey: ContentKey,
          ContentItemKey: ContentItemKey
        }
      })
    }).$mount(document.getElementById(id))
  })
})
